import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginModel} from '../models/users/login.model';
import {Observable} from 'rxjs';
import {AuthService} from '../core/auth/auth.service';
import {GlobalConfig} from '../core/config/global.config';
import {UserSearchModel} from '../models/users/user-search.model';

@Injectable({ providedIn: 'root' })
export class UserAccountService {
  constructor(private httpClient: HttpClient, public authService: AuthService) {}

  login(loginModel: LoginModel) {
    return this.authService.login(loginModel);
  }

  get() {
    return this.httpClient.get(GlobalConfig.ADD_API_URL('/accounts/detail'));
  }

  searchAccountsBy(userSearch: UserSearchModel) {
    const query = this.toQueryString(userSearch);
    return this.httpClient.get(GlobalConfig.ADD_API_URL('/accounts/') + userSearch.username);
  }

  private toQueryString(obj: any) {
    let queryString = '?';
    Object.keys(obj).forEach(key => {
      queryString = queryString + key + '=' + obj[key] + '&';
    });
    return queryString;
  }
}
